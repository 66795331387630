<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="群组名" prop="title">
        <el-input v-model="info.title" placeholder="请输入群组名"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item label="菜单">
        <div style="height: 400px; overflow-y: scroll;">
          <el-tree
            :data="treedata"
            @check-change="treeChange"
            show-checkbox
            default-expand-all
            node-key="id"
            ref="tree"
            highlight-current
            check-strictly
            :default-checked-keys="choosedata"
            :props="treeprops">
          </el-tree>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

export default {
  data() {
    return {
      treedata: [],
      choosedata: [],
      treeprops: {
        children: 'children',
        label: 'title'
      },
      info: {
        id: 0,
        title: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入群组名', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getMenuIndex()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getMenuIndex() {
      var that = this
      this.$api.merchant.menuIndex({},function (res) {
        if(res.errcode == 0) {
          that.treedata = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.merchant.groupDetail({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
          if(that.info.auth_ids) {
            that.choosedata = that.info.auth_ids.split(',')
          }
          console.log(that.choosedata)
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    treeChange(data, checked, ind) {
      var node = this.$refs.tree.getCheckedNodes()
      var nodes = []
      node.filter(function (item) {
        nodes.push(item.id)
      })
      this.choosedata = nodes
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = {
            id: that.info.id,
            title: that.info.title,
            ordering: that.info.ordering,
            ids: that.choosedata
          }
          if(this.info.id == 0) {
            this.$api.merchant.groupAdd(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            this.$api.merchant.groupEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          }
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
